import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KurzConfigurationDisplayComponent } from './kurz-configuration-display.component';
import { I18nModule } from '@spartacus/core';
import { KurzUnitPipesModule } from 'src/app/custom/pipes/kurz-unit/kurz-unit-pipes.module';
import { KurzCuttingDisplayComponent } from './kurz-cutting-display/kurz-cutting-display.component';
import { ColorThemeModule } from '@util/components/shared/color-theme/color-theme.module';
import { IconButtonModule } from '@util/components/icon-button/icon-button.module';
import { KurzDifferentlyStyledValueModule } from '../kurz-differently-styled-value/kurz-differently-styled-value.module';
import { DifferentlyStyledStringParserPipesModule } from '../kurz-differently-styled-value/string-parser/pipes/differently-styled-string-parser-pipes.module';

@NgModule({
  declarations: [
    KurzConfigurationDisplayComponent,
    KurzCuttingDisplayComponent
  ],
  imports: [
    CommonModule,
    I18nModule,
    KurzUnitPipesModule,
    IconButtonModule,
    ColorThemeModule,
    KurzDifferentlyStyledValueModule,
    DifferentlyStyledStringParserPipesModule
  ],
  exports: [
    KurzConfigurationDisplayComponent,
    KurzCuttingDisplayComponent
  ]
})
export class KurzConfigurationDisplayModule { }
